import React from "react";
import { alertService, userService } from "../../services";
import OtpInput from "react-otp-input";
import { toast } from 'react-toastify';


import {
   Button,
   Card,
   CardBody,
   FormGroup,
   Form,
   Input,
   InputGroupAddon,
   InputGroupText,
   InputGroup,
   Col,
   Modal,
   ModalBody,
   Row
} from "reactstrap";
const otpLength = process.env.REACT_APP_OTP_LENGTH;
const captchaEnable = process.env.REACT_APP_CAPTCHA_ENABLE;

toast.configure()

class Login extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         name: "",
         password: "",
         open: false,
         userOTP: "",
         role: "",
         isVerified: false,
         captchaResponse: null
      }
   }


   handleOtpChange = (otp) => this.setState({ userOTP: otp })
   handleChange = event => {
      this.setState({
         [event.target.name]: event.target.value
      });
   }

   // Captcha v3 implementation

   handleSubmit = (e) => {
      e.preventDefault();
      if (captchaEnable === 'true') {
         window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LcpNPYbAAAAAOxxXR60pa4HIFvXbm2gdarx69ce', { action: 'submit' }).then(token => {
               this.setState({
                  captchaResponse: token
               })
               const data = {
                  username: this.state.name,
                  password: this.state.password,
                  captchaResponse: this.state.captchaResponse
               }
               this.onFormSubmit(data);
            });
         });
      }
      else{
         const data = {
            username: this.state.name,
            password: this.state.password,
         }
         this.onFormSubmit(data);
      }

   }

   handleOtpSubmit = event => {
      event.preventDefault();
      const data = {
         username: this.state.name,
         password: this.state.password,
         otp: this.state.userOTP,

      }
      this.onOtpSubmit(data);
   }

   onFormSubmit(data) {
      userService.login(data)
         .then((res) => {

            if (res && res.respCode === 401) {
               toast.error(res.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else if (res && res.respCode === 200) {
               if (res && res.data.id_token) {
                  localStorage.setItem('token', res.data.id_token);
                  this.roleBasedRoutes()
               } else {
                  this.setState({ open: !this.state.open })
               }
            }
         })
         .catch(alertService.error);
   }

   onOtpSubmit(data) {
      return userService
         .loginotp(data)
         .then((res) => {
            if (res.respCode === 200) {
               if (res.data.idToken) {
                  localStorage.setItem("token", res.data.idToken);
                  this.roleBasedRoutes()
               }
            } else {
               toast.error("OTP Validation Failed", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               this.setState({ userOTP: "" })
            }
         })
         .catch(alertService.error);
   }

   roleBasedRoutes = () => {
      if (localStorage.getItem('token')) {
         toast.success("Welcome to Zong Smart Number portal", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         })
         this.props.history.push("/csiv2/user")
      }
   }


   render() {
      return (
         <>
            <Col lg="5" md="7">
               <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-3">
                     <div className="text-center text-muted mb-4">
                        <small>Sign in with credentials</small>
                     </div>
                     <Form onSubmit={this.handleSubmit}>
                        <FormGroup className="mb-3">
                           <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                 <InputGroupText>
                                    <i className="ni ni-single-02" />
                                 </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                 name="name"
                                 placeholder="Username"
                                 value={this.state.name}
                                 onChange={this.handleChange}
                              />
                           </InputGroup>
                        </FormGroup>
                        <FormGroup>
                           <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                 <InputGroupText>
                                    <i className="ni ni-lock-circle-open" />
                                 </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                 type="password"
                                 name="password"
                                 placeholder="Password"
                                 value={this.state.password}
                                 onChange={this.handleChange}
                              />
                           </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                           <Button disabled={(this.state.name && this.state.password) ? false : true}
                              className="my-1" color="primary" type="submit">
                              Sign in
                           </Button>
                           <Modal autoFocus={false} size="sm" centered={true} isOpen={this.state.open}>
                              <ModalBody>
                                 <h2>Enter OTP code</h2>
                                 <OtpInput
                                    className="form-group col-2"
                                    inputStyle={{
                                       width: "3rem",
                                       height: "3rem",
                                       fontSize: "2rem",
                                       borderRadius: 4,
                                       border: "2px solid rgba(0,0,0,0.3)",
                                    }}

                                    name="opt"
                                    value={this.state.userOTP}
                                    onChange={this.handleOtpChange}
                                    numInputs={otpLength}
                                    shouldAutoFocus={true}
                                    isInputNum={true}
                                    disabled={false}
                                    secure
                                 />
                                 <Row className="float-right">
                                    <Button
                                       size="sm" color="primary"
                                       onClick={this.handleOtpSubmit}>
                                       Submit
                                    </Button>
                                    <Button
                                       size="sm"
                                       color="danger"
                                       onClick={() =>
                                          this.setState({
                                             open: !this.state.open,
                                             userOTP: ""
                                          })}>
                                       Close
                                    </Button>
                                 </Row>
                              </ModalBody>
                           </Modal>
                        </div>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
         </>
      );
   }
}

export default Login;
